import React from "react"

import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// Favicons
import favicon16 from "../images/favicon16.png";
import favicon32 from "../images/favicon32.png";
import favicon96 from "../images/favicon96.png";
// import metaImage from "../images/og_image 03.png";


function SEO({ description, lang, meta, title, image}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )


  const defaults = site.siteMetadata;
  
  // Check if `defaults` is being run in a web-page inside a web-browser or not and if url exists
  
  if (defaults.url === '' && typeof window!== 'undefined') {
    defaults.url = window.location.origin;
  }
  
  
  if (defaults.url === '') {
    console.error('Please set a baseUrl in your site metadata!');
  }
  
  const metaDescription = description || site.siteMetadata.description;
  const url = new URL(site.path || '', defaults.url);
  // const metaImage = site.image ? new URL(site.image, defaults.url) : false;
  const metaImage = 'https://imgur.com/5vPTyKe.png'


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
     

      link ={[
        { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${favicon16}` },
        { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${favicon32}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon96}` },
      ]}

    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
